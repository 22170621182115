<template>
  <header class="header">
    <v-container
      class="d-flex justify-space-between align-center header__wrap pb-0"
    >
      <div
        class="burger mr-md-3 d-block d-lg-none flex-shrink-0"
        :class="{ active: menu }"
        @click="toggle"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <router-link to="/" class="logo ml-4">
        <img class="d-none d-md-block" src="@/assets/logo.svg" alt="logo">
        <img class="mini_logo d-md-none" src="@/assets/logo-mini.svg" alt="logo">
      </router-link>

      <div class="d-flex align-center">
        <div class="user_block d-flex align-center">
<!--          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" fab small text @click="showPdfPublicContract = true">
                <v-img height="40px" width="20px" src="@/assets/icon_contract.svg"/>
              </v-btn>
            </template>
            <span>Публічний Договір</span>
          </v-tooltip>

          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab small text class="mx-2" @click="downloadFile('https://land.e-vybir.ua/files/bid.xlsx', '2023.002 - Заява-приєднання до умов Публічного Договору, АКТ.xlsx')">
                <v-img height="40px" width="20px" src="@/assets/icon_download.svg"/>
              </v-btn>
            </template>
            <span>Заява-приєднання</span>
          </v-tooltip>-->
          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab small text color="white" @click.stop="showInstruction">
                <v-icon size="45">mdi-help-circle-outline</v-icon></v-btn>
            </template>
            <span>Загальна інформація</span>
          </v-tooltip>
          <div class="user_menu_item ml-5" v-if="!isAuthed">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="{ name: 'Login' }">
                  <v-icon size="45" v-bind="attrs" v-on="on">mdi-account-outline</v-icon>
                </router-link>
              </template>
              <span>Увійти</span>
            </v-tooltip>
          </div>

          <div class="user_menu_item ml-5" v-if="isAuthed">
            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-img class="header_user_pic" :src="getUserAvatar"></v-img>
                </div>
              </template>

              <v-list>
                <v-list-item
                  v-for="(userCabinetItem, index) in userCabinetItems"
                  :key="index"
                >
                  <v-list-item-title>
                    <router-link
                      class="menu_item"
                      :to="{ name: userCabinetItem.link }"
                    >
                      <v-icon class="mr-2">{{ userCabinetItem.icon }}</v-icon>
                      {{ userCabinetItem.title }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>
                    <div class="menu_item">
                      <v-icon class="mr-2">mdi-exit-to-app</v-icon>
                      Вийти
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-container>
    <v-navigation-drawer v-model="menu" fixed left>
      <div class="mob_menu_header d-flex align-center">
        <router-link class="ml-4" :to="{ name: 'Login' }" v-if="!isAuthed">
          Увiйти
        </router-link>

        <router-link
          class="d-flex align-center pl-4"
          to="/account/info"
          v-if="isAuthed"
        >
          <v-img
            class="header_user_pic flex-grow-0 mr-2"
            :src="getUserAvatar"
          ></v-img>
          <span class="header_username">{{ fullName }}</span>
        </router-link>

        <div
          class="burger burger_nav ml-auto mr-3"
          :class="{ active: menu }"
          @click="toggle"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <v-divider />
      <v-list nav dense>
        <v-list-item-group v-if="isAuthed">
          <v-list-item
            v-for="(userCabinetItem, index) in userCabinetItems"
            :key="index"
            link
            :to="{ name: userCabinetItem.link }"
          >
            <v-icon class="mr-4">{{ userCabinetItem.icon }}</v-icon>
            <v-list-item-title>{{ userCabinetItem.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-icon class="mr-4">mdi-exit-to-app</v-icon>
            <v-list-item-title>Вийти</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
<!--    <v-dialog
      max-width="800px"
      v-model="showPdfPublicContract"
      @keydown.esc="closeDetailDialog"
    >
      <iframe style="width: 100%;height: 800px; border: none;" src="https://land.e-vybir.ua/files/public-contract.pdf"/>
    </v-dialog>-->
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { downloadFile } from '@/utils'

export default {
  name: 'HeaderComponent',
  data: () => ({
    token: '',
    menu: false,
    menuMobile: false,
    userCabinetItems: [
      {
        title: 'Персональні данi',
        link: 'Info',
        icon: 'mdi-account'
      },
      {
        title: 'Налаштування',
        link: 'AccountSettings',
        icon: 'mdi-cog'
      }
    ],
    downloadFile: downloadFile,
    showPdfPublicContract: false
  }),
  computed: {
    ...mapGetters('auth', ['isAuthed']),
    ...mapGetters('user', ['getUser']),
    fullName () {
      if (!this.getUser) return ''
      return `${this.getUser.firstName} ${this.getUser.lastName}`
    },
    getUserAvatar () {
      if (!this.getUser) return ''
      return (
        this.getUser.avatar || 'https://randomuser.me/api/portraits/men/85.jpg'
      )
    }
  },
  methods: {
    ...mapMutations('map', ['setDialogInstructionState']),
    toggle () {
      this.menu = !this.menu
    },
    logout () {
      this.$store.commit('auth/logOut')
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie))
      this.$store.commit('user/resetPrivacyState')
      this.$store.commit('map/resetPrivacyState')
      this.$router.push('/').catch(() => {})
    },
    showInstruction () {
      this.setDialogInstructionState(true)
    }
  }
}
</script>
